import React, {lazy, Suspense} from 'react';
import {Route, Router, Switch} from 'react-router-dom';
import {SecureRoute} from '@okta/okta-react';
import {createBrowserHistory} from 'history';

import {Routes, StatelessRoutes} from './routes';
import NotFound from './NotFound';
import ForgotPassword from './ForgotPassword';
import ResetPassword from './ResetPassword';
import SignIn from './SignIn';
import Terms from './Terms';
import Privacy from './Privacy';
import FullPrivacyPolicy from './FullPrivacyPolicy';
import About from './About';
import Contact from './Contact';
import FAQ from './FAQ';
import Support from './Support';
import Navigation from '../components/Navigation';
import ErrorBoundary from './ErrorBoundary';
import AppWithRouterAccess from '../hocs/AppWithRouterAccess';
import Preloader from '../components/Preloader';
import UserSelfActivation from './UserSelfActivation';
import Footer from '../components/Footer';
import HelpInsurance from './HelpInsurance';
import HASignOn from './HASingOn';
import AdminRoute from '../hocs/AdminRoute';
import LoginCallback from './LoginCallback';
import EndOfServiceModal from '../components/EndOfServiceModal';

const Home = lazy(() => import('./Home'));
const MyProfile = lazy(() => import('./MyProfile'));
const Compare = lazy(() => import('./Compare'));
const DrugLookup = lazy(() => import('./DrugLookup'));

const CreateUserForHA = lazy(() => import('./CreateUser/components/CreateUserForHA'));
const SignInErrorHA = lazy(() => import('./SignIn/HealthAdvocate/pages/SignInError'));
const TermsDeclinedHA = lazy(() => import('./SignIn/HealthAdvocate/pages/TermsDeclined'));
const SignedOutHA = lazy(() => import('./SignIn/HealthAdvocate/pages/SignedOut'));
const AdminUsersDashboard = lazy(() => import('./Admin/Users/Dashboard'));
const AdminCreateUser = lazy(() => import('./Admin/Users/Create'));
const AdminOrganizationsDashboard = lazy(() => import('./Admin/Organizations/Dashboard'));
const AdminOrganizationsCreate = lazy(() => import('./Admin/Organizations/Create'));
const AdminEditUser = lazy(() => import('./Admin/Users/Edit'));
const BulkAddErrors = lazy(() => import('./Admin/Users/BulkAction/Errors'));
const BulkActionSuccess = lazy(() => import('./Admin/Users/BulkAction/Success'));
const AdminDashboard = lazy(() => import('./Admin/Dashboard'));
const AdminEditOrganization = lazy(() => import('./Admin/Organizations/Edit'));

export const history = createBrowserHistory();

const Routing = () => {
  return (
    <Router history={history}>
      <AppWithRouterAccess>
        <ErrorBoundary>
          <EndOfServiceModal />
          <Suspense fallback={<Preloader />}>
            <Switch>
              <Route path={Routes.HOME} component={Home} exact />
              <SecureRoute
                path={Routes.ME}
                render={() => (
                  <>
                    <Navigation />
                    <MyProfile />
                    <Footer />
                  </>
                )}
              />

              <Route path={Routes.HA_SIGN_ON} component={HASignOn} />

              <Route path={Routes.LOGIN_CALLBACK} component={LoginCallback} />

              <Route path={Routes.SIGN_IN} component={SignIn} />
              <Route path={Routes.RESET_PASSWORD} component={ResetPassword} />
              <Route path={Routes.FORGOT_PASSWORD} component={ForgotPassword} />

              <Route
                path={[Routes.HEALTH_ADVOCATE, Routes.HEALTH_ADVOCATE_MERCER]}
                component={CreateUserForHA}
              />

              <Route path={Routes.FAQ} component={FAQ} />
              <Route path={Routes.PRIVACY} component={Privacy} />
              <Route path={Routes.FULL_PRIVACY_POLICY} component={FullPrivacyPolicy} />
              <Route path={Routes.HELP_INSURANCE} component={HelpInsurance} />
              <Route path={Routes.SUPPORT} component={Support} />
              <Route path={Routes.HA_SIGN_IN_ERROR} component={SignInErrorHA} />
              <Route path={Routes.HA_TERMS_DECLINED} component={TermsDeclinedHA} />
              <Route path={Routes.HA_SIGNED_OUT} component={SignedOutHA} />

              <SecureRoute path={Routes.TERMS} component={Terms} />
              <Route path={Routes.ABOUT} component={About} />
              <SecureRoute path={Routes.CONTACT} component={Contact} />
              <SecureRoute path={Routes.DRUG_LOOKUP} component={DrugLookup} />

              <SecureRoute path={Routes.COMPARE} component={Compare} />
              <Route path={Routes.ACTIVATE_USER} component={UserSelfActivation} />
              <AdminRoute>
                <SecureRoute
                  exact
                  path={StatelessRoutes.BULK_ADD_ERRORS}
                  component={BulkAddErrors}
                />
                <SecureRoute
                  exact
                  path={StatelessRoutes.BULK_ACTION_ERRORS}
                  component={BulkAddErrors}
                />
                <SecureRoute
                  exact
                  path={StatelessRoutes.BULK_ACTION_SUCCESS}
                  component={BulkActionSuccess}
                />
                <SecureRoute
                  exact
                  path={Routes.ADMIN_ORGANIZATIONS}
                  component={AdminOrganizationsDashboard}
                />
                <SecureRoute
                  exact
                  path={Routes.ADMIN_ORGANIZATIONS_CREATE}
                  component={AdminOrganizationsCreate}
                />
                <SecureRoute
                  exact
                  path={Routes.ADMIN_ORGANIZATIONS_EDIT}
                  component={AdminEditOrganization}
                />
                <SecureRoute exact path={Routes.ADMIN_USERS} component={AdminUsersDashboard} />
                <SecureRoute path={Routes.ADMIN_USERS_CREATE} component={AdminCreateUser} />
                <SecureRoute exact path={Routes.ADMIN_USERS_EDIT} component={AdminEditUser} />
                <SecureRoute exact path={Routes.ADMIN_DASHBOARD} component={AdminDashboard} />
              </AdminRoute>
              <Route component={NotFound} />
            </Switch>
          </Suspense>
        </ErrorBoundary>
      </AppWithRouterAccess>
    </Router>
  );
};

export default Routing;
