import React, {useEffect} from 'react';
import styled from 'styled-components/macro';
import {useLocation} from 'react-router';
import {Routes} from 'pages/routes';

import {ModalBlur} from '../Modal/ModalBlur';
import {StyledModal} from './styles';
import {EndOfServiceModalContent} from './styles';
import {ConfirmActionProps} from './types';
import {StyledButton as StyledButtonCommon} from '../Button/components/SubmitButton';

const storageKey = 'endOfService';

const StyledButton = styled(StyledButtonCommon)`
  &.ant-btn {
    ${({theme}) => theme.forWidth('max', 'sm', `max-width: none;`)}
  }
`;

const StyledModalWrapper = styled(StyledModal)`
  & .ant-modal-title {
    ${({theme}) =>
      theme.forWidth('max', 'sm', `font-size: 1.5rem; text-align: center; line-height: normal;`)}
  }
  & .ant-modal-body {
    & > div {
      ${({theme}) => theme.forWidth('max', 'sm', `height: auto;`)}
    }
    ${({theme}) =>
      theme.forWidth('max', 'sm', `font-size: 1rem; text-align: center; line-height: normal;`)}
  }
`;

const exceptionRoutes: string[] = [Routes.LOGIN_CALLBACK, Routes.SIGN_IN, '/'];

const EndOfServiceModal = (props: ConfirmActionProps) => {
  const [show, setShow] = React.useState(false);
  const {pathname} = useLocation();

  useEffect(() => {
    const item = sessionStorage.getItem(storageKey);
    const excluded = exceptionRoutes.some((route: string) => route === pathname);
    if (!item && !excluded) {
      setShow(true);
    }
  }, [pathname]);

  const close = () => {
    sessionStorage.setItem(storageKey, 'true');
    setShow(false);
  };

  return (
    <>
      {show && <ModalBlur />}
      <StyledModalWrapper
        {...props}
        visible={show}
        closable={false}
        footerRenderer={() => [
          <StyledButton primaryGreen data-e2e='footer__ok-button' onClick={close}>
            OK
          </StyledButton>,
        ]}
        title='🔔 oneRx Will Be Discontinued on May 15th'
      >
        <EndOfServiceModalContent>
          <p>
            The oneRx platform will no longer be available after <b>May 15, 2025</b>. Thank you for
            being a valued oneRx user.
          </p>
        </EndOfServiceModalContent>
      </StyledModalWrapper>
    </>
  );
};

export default EndOfServiceModal;
